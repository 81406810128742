<template>
  <div class="mx-1 pt-16">
    <v-row style="max-width: 1100px;" class="mx-auto pt-16 mt-8">
      <v-col class="pt-16 mt-16">
        <p class="mb-0 font-weight-bold" style="color: #00C853">{{$t('prices.price_calculator.title')}}</p>
        <h2 class="pr-8">{{$t('prices.price_calculator.header')}}</h2>
        <p class="pr-16">{{$t('prices.price_calculator.text')}}</p>
      </v-col>
      <v-col>
        <Calculator></Calculator>
        <v-img
            max-width="400px"
            src="@/assets/color1.svg"
            contain
            rounded
            class="rounded"
            style="margin-top: -250px; margin-left: -20px; position: relative; z-index: 1; transform: rotate(15deg);"
        >
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calculator from "./Calculator";

export default {
  name: "PricesCalculator",
  components: {
    Calculator
  }
}
</script>

<style scoped>

</style>